import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VCheckbox,{attrs:{"type":"checkbox","value":"1","label":"Keep me logged in","color":"primary","disabled":_vm.waiting},model:{value:(_vm.form.remember),callback:function ($$v) {_vm.$set(_vm.form, "remember", $$v)},expression:"form.remember"}}),_c(VBtn,{staticClass:"mb-3 white",attrs:{"block":"","large":"","disabled":_vm.waiting},on:{"click":function($event){return _vm.googleSignIn()}}},[_c(VRow,[_c(VAvatar,{attrs:{"size":"35"}},[_c(VImg,{attrs:{"src":require('@/assets/images/google@3x.png')}})],1)],1),_c(VRow,[_vm._v(" Google Login/Sign Up ")])],1),_c(VBtn,{staticClass:"mb-3 white",attrs:{"block":"","large":"","disabled":_vm.waiting},on:{"click":function($event){return _vm.facebookSignIn()}}},[_c(VRow,[_c(VAvatar,{attrs:{"size":"35"}},[_c(VImg,{attrs:{"src":require('@/assets/images/facebook@3x.png')}})],1)],1),_c(VRow,[_vm._v(" Facebook Login/Sign Up ")])],1),_c(VBtn,{staticClass:"mb-7 white",attrs:{"block":"","large":"","disabled":_vm.waiting},on:{"click":function($event){return _vm.appleSignIn()}}},[_c(VRow,[_c(VAvatar,{attrs:{"size":"35"}},[_c(VImg,{attrs:{"src":require('@/assets/images/apple-white-logo-masked-circular.png')}})],1)],1),_c(VRow,[_vm._v(" Apple Login/Sign Up ")])],1),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredEmail|email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"email","label":"Email","error-messages":errors[0],"solo":"","disabled":_vm.waiting},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredPassword|minPassword:6","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"password","label":"Password","error-messages":errors[0],"solo":"","disabled":_vm.waiting},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c(VBtn,{staticClass:"mb-3 primary",attrs:{"type":"submit","block":"","large":"","disabled":invalid || _vm.waiting},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Email & Password Login")]),_c(VBtn,{staticClass:"mb-3 indigo white--text",attrs:{"block":"","large":"","to":{ name: 'SignUp' },"disabled":_vm.waiting}},[_vm._v("Email & Password Sign Up")]),_c(VBtn,{attrs:{"block":"","large":"","to":{ name: 'PasswordRecovery' },"disabled":_vm.waiting}},[_vm._v("Password Recovery For Email")]),_c(VRow,{staticClass:"mt-3",attrs:{"justify":"space-around"}},[_c('a',{attrs:{"href":"https://reflexapp.io/terms-conditions/","target":"_blank"}},[_vm._v("Terms + Conditions")]),_c('a',{attrs:{"href":"https://reflexapp.io/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")])]),_c(VRow,[_c('p',{staticClass:"mt-4 text-caption font-italic"},[_vm._v("Creating an account on The Reflex Web Portal is not a subscription to Reflex or Reflex Pro and you will not be billed for accessing learning content.")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }