import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredName|minName:3","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"name","name":"name","error-messages":errors[0],"label":"Name","solo":"","disabled":_vm.submitting},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredEmail|email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"email","name":"email","error-messages":errors[0],"label":"Email","solo":"","disabled":_vm.submitting},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredPassword|minPassword:6|password:confirmation","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"password","name":"password","error-messages":errors[0],"label":"Password","solo":"","disabled":_vm.submitting},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirmation","mode":"aggressive","rules":"requiredPassword|minPassword:6","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"password","name":"password_confirmation","error-messages":errors[0],"label":"Confirm the password","solo":"","disabled":_vm.submitting},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c(VBtn,{staticClass:"mb-3 primary",attrs:{"type":"submit","block":"","large":"","disabled":invalid || _vm.submitting},on:{"click":function($event){return _vm.submit()}}},[_vm._v("REGISTER ")]),_c(VBtn,{staticClass:"indigo white--text",attrs:{"to":{ name: 'Login' },"block":"","large":"","disabled":_vm.submitting}},[_vm._v("ALREADY HAVE AN ACCOUNT? ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }