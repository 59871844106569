import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredEmail|email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"email","name":"email","label":"Email","error-messages":errors[0],"solo":"","disabled":_vm.submitting},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c(VBtn,{staticClass:"mb-3 primary",attrs:{"type":"submit","block":"","large":"","disabled":invalid || _vm.submitting},on:{"click":function($event){return _vm.submit()}}},[_vm._v("SEND PASSWORD RESET LINK")]),_c(VBtn,{attrs:{"block":"","large":"","to":{ name: 'Login' },"disabled":_vm.submitting}},[_vm._v("BACK")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }